import React from 'react';
import logo from '../assets/Unit326.png'
function Header() {
    return (<>


        <header className="absolute inset-x-0 top-0 z-10 w-full">
            <div className="px-4 mx-auto sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 lg:h-20">
                    <div className="flex-shrink-0">
                        <a href="/" title="" className="flex">
                            <img className={'rounded-2xl'} src={logo} width={50} height={50}></img>
                             </a>
                    </div>

                    <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
                        <a href="" title="" className="text-base text-white transition-all duration-200 hover:text-opacity-80"> Actral </a>

                        <a href="/hiking-trails-za" title="" className="text-base text-white transition-all duration-200 hover:text-opacity-80"> HTZA </a>

                        </div>


                </div>
            </div>
        </header>






    </>);
}

export default Header;
