import React from 'react';
import Footer from "./footer";

import Header from "./header";
import ReactGA from "react-ga4";

ReactGA.send(window.location.pathname);

function Home() {
    return (<>


        <div className="bg-gradient-to-b from-[#101212] relative to-[#08201D]">
<Header/>

            <section className="relative lg:min-h-[1000px] pt-32 pb-10 sm:pt-40 sm:pb-16 lg:pb-24">


                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative z-20">
                    <div className="max-w-xl mx-auto text-center">
                        <h1 className="text-4xl font-bold sm:text-6xl">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-white"> Unit 326 </span>
                        </h1>
                        <p className="mt-5 text-base text-white sm:text-xl">Software solutions that <span
                            className={'text-green-300'}>actually</span> meet people's needs</p>




                        <div className="max-w-xl text-white pt-40 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

                            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                              <span className="relative inline-block">
                                <svg
                                    viewBox="0 0 52 24"
                                    fill="white"
                                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-white lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                                >
                                  <defs>
                                    <pattern
                                        id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                      <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                  </defs>
                                  <rect
                                      fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                                      width="52"
                                      height="24"
                                  />
                                </svg>
                                <span className="relative text-white">Products.</span>
                              </span>
                            </h2>

                        </div>
                        <div className="grid grid-cols-1 px-20 mt-12 text-left gap-x-12 gap-y-8 sm:grid-cols-2 sm:px-0">
                            <div className=" items-center">
                                <a target={'_blank'} href="https://www.actral.app/" className={'text-decoration-none '}>
                                    <h6 className="mb-2 font-semibold text-lg bg-gradient-to-r from-green-400 via-blue-800 to-purple-800 bg-clip-text  text-transparent  leading-5">Actral</h6>
                                </a>
                                <p className=" text-sm text-white">Securely trade and manage your XRP wallet</p>
                            </div>

                            <div className=" items-center">
                                <a href={'hiking-trails-za'} className={'text-decoration-none'}>
                                    <h6 className="mb-2 text-lg bg-gradient-to-r from-green-400 via-blue-800 to-purple-800 bg-clip-text  font-extrabold text-transparent  leading-5">Bundu</h6>
                                </a>
                                <p className="text-sm text-white"> Find the most breath-taking hiking trails in South
                                    Africa</p>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>




        <Footer />


    </>);
}

export default Home;
